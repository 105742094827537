import {AfterContentInit, Component, HostListener, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {SocketData} from '../_models/socketData';
import {HomeProvider} from '../_services/home.provider';
import {Subscription} from 'rxjs';
import {Categories} from '../_models/categories';
import AOS from 'aos';
import {CarouselComponent} from '../_components/carousel/carousel.component';
import {AnimationType} from '../_components/carousel/carousel.animation';
import {Slide} from '../_components/carousel/carousel.interface';
import {CATEGORYTYPE} from "../_models/categoryType";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [],
})
export class HomeComponent implements OnInit, OnDestroy {
  categories: Categories[] = [];
  clickDoviz = true;
  clickParite = false;
  clickZiynet = false;
  clickMaden = false;
  clickGramAltin = false;
  code: string;

  socketDataList: SocketData[] = [];
  dataList1: SocketData[] = [];
  dataList2: SocketData[] = [];
  dataList3: SocketData[] = [];
  dataList4: SocketData[] = [];
  dataList5: SocketData[] = [];
  dataList6: SocketData[] = [];
  dataListReplace1: SocketData[] = [];
  dataListReplace2: SocketData[] = [];
  dataListReplace3: SocketData[] = [];
  dataListReplace4: SocketData[] = [];
  dataListReplace5: SocketData[] = [];
  dataListReplace6: SocketData[] = [];

  private subscriptions = new Subscription();
  public pingStatus = true;
  RETRY_SECONDS = 30;
  timer: any;
  interval: any;

  constructor(private service: HomeProvider,
              private renderer: Renderer2) {
  }

  ngOnInit(): void {
    this.interval = setInterval(() => {
      if (this.pingStatus === false) {
        this.subscriptions.unsubscribe();
        this.subscriptions = new Subscription();
        this.getSocketData();
      }
    }, this.RETRY_SECONDS * 1000);
    this.getSocketData();
    this.categories = this.service.getCategories();
    AOS.init();
  }

  getSocketData() {
    this.service.initSocket();

    this.subscriptions.add(this.service.connectWebSocket().subscribe((Sdata: SocketData[]) => {
        clearTimeout(this.timer);
        this.pingStatus = true;
        this.socketDataList = Sdata;
        this.filterCategories();
        this.timer = setTimeout(() => {
          this.pingStatus = false;
        }, 2000);
      },
      (err) => {
        this.pingStatus = true;
      },
      () => {
        this.pingStatus = false;
      }));
  }

  trackByPrice(index: number, code) {
    return code.Ask;
  }

  filterCategories() {
    this.dataList1 = [];
    this.dataList2 = [];
    this.dataList3 = [];
    this.dataList4 = [];
    this.dataList5 = [];
    this.dataList6 = [];
    this.socketDataList.forEach((item, index) => {
      if (item.Category === CATEGORYTYPE.PARITE) {
        this.dataList2.push(item);
      } else if (item.Category === CATEGORYTYPE.DOVIZ) {
        this.dataList3.push(item);
      } else if (item.Category === CATEGORYTYPE.MADEN) {
        this.dataList4.push(item);
      } else if (item.Category === CATEGORYTYPE.SARRAFIYE) {
        this.dataList5.push(item);
      } else {
        this.dataList6.push(item);
      }
    });
    if (this.dataListReplace1.length !== 0) {
      if (JSON.stringify(this.dataListReplace1) === JSON.stringify(this.dataList1)) {

      } else {
        this.dataList1.forEach((data, index) => {
          if (data.Ask !== this.dataListReplace1[index].Ask) {
            this.percentChange(data, this.dataListReplace1[index], index);
          } else {
            data.askPercentChange = 0.00;
            this.dataListReplace1[index].askPercentChange = data.askPercentChange;
          }
        });
      }
    } else {
      this.dataListReplace1 = this.dataList1;
    }
    if (this.dataListReplace2.length !== 0) {
      if (JSON.stringify(this.dataListReplace2) === JSON.stringify(this.dataList2)) {

      } else {
        this.dataList2.forEach((data, index) => {
          if (data.Ask !== this.dataListReplace2[index].Ask) {
            this.percentChange(data, this.dataListReplace2[index], index);
          } else {
            if (data.askPercentChange) {
              this.dataListReplace2[index].askPercentChange = data.askPercentChange;
            } else {
              data.askPercentChange = 0.00;
              this.dataListReplace2[index].askPercentChange = data.askPercentChange;
            }
          }
        });
      }
    } else {
      this.dataListReplace2 = this.dataList2;
    }
    if (this.dataListReplace3.length !== 0) {
      if (JSON.stringify(this.dataListReplace3) === JSON.stringify(this.dataList3)) {

      } else {
        this.dataList3.forEach((data, index) => {
          if (data.Ask !== this.dataListReplace3[index].Ask) {
            this.percentChange(data, this.dataListReplace3[index], index);
          } else {
            if (data.askPercentChange) {
              this.dataListReplace3[index].askPercentChange = data.askPercentChange;
            } else {
              data.askPercentChange = 0.00;
              this.dataListReplace3[index].askPercentChange = data.askPercentChange;
            }
          }
        });
      }
    } else {
      this.dataListReplace3 = this.dataList3;
    }
    if (this.dataListReplace4.length !== 0) {
      if (JSON.stringify(this.dataListReplace4) === JSON.stringify(this.dataList4)) {

      } else {
        this.dataList4.forEach((data, index) => {
          if (data.Ask !== this.dataListReplace4[index].Ask) {
            this.percentChange(data, this.dataListReplace4[index], index);
          } else {
            if (data.askPercentChange) {
              this.dataListReplace4[index].askPercentChange = data.askPercentChange;
            } else {
              data.askPercentChange = 0.00;
              this.dataListReplace4[index].askPercentChange = data.askPercentChange;
            }
          }
        });
      }
    } else {
      this.dataListReplace4 = this.dataList4;
    }
    if (this.dataListReplace5.length !== 0) {
      if (JSON.stringify(this.dataListReplace5) === JSON.stringify(this.dataList5)) {

      } else {
        this.dataList5.forEach((data, index) => {
          if (data.Ask !== this.dataListReplace5[index].Ask) {
            this.percentChange(data, this.dataListReplace5[index], index);
          } else {
            if (data.askPercentChange) {
              this.dataListReplace5[index].askPercentChange = data.askPercentChange;
            } else {
              data.askPercentChange = 0.00;
              this.dataListReplace5[index].askPercentChange = data.askPercentChange;
            }
          }
        });
      }
    } else {
      this.dataListReplace5 = this.dataList5;
    }
    if (this.dataListReplace6.length !== 0) {
      if (JSON.stringify(this.dataListReplace6) === JSON.stringify(this.dataList6)) {

      } else {
        this.dataList6.forEach((data, index) => {
          if (data.Ask !== this.dataListReplace6[index].Ask) {
            this.percentChange(data, this.dataListReplace6[index], index);
          } else {
            if (data.askPercentChange) {
              this.dataListReplace6[index].askPercentChange = data.askPercentChange;
            } else {
              data.askPercentChange = 0.00;
              this.dataListReplace6[index].askPercentChange = data.askPercentChange;
            }
          }
        });
      }
    } else {
      this.dataListReplace6 = this.dataList6;
    }

  }

  percentChange(newData, oldData, index) {
    if (newData.Ask !== oldData.Ask) {
      let oldAskPrice = +oldData.Ask;
      let newAskPrice = +newData.Ask;
      let askPriceDifference = (1 - (oldAskPrice / newAskPrice)) * 100;
      newData.askPercentChange = +askPriceDifference.toFixed(2);
      newData.Time = Date.now();
    }
  }

  clickRequestButton(requestType) {
    const element = document.getElementById(requestType);

    switch (requestType) {
      case 'DOVIZ': {
        this.clickDoviz = true;
        this.clickGramAltin = false;
        this.clickMaden = false;
        this.clickParite = false;
        this.clickZiynet = false;
        break;
      }
      case 'ZIYNET': {
        this.clickDoviz = false;
        this.clickGramAltin = false;
        this.clickMaden = false;
        this.clickParite = false;
        this.clickZiynet = true;
        break;
      }
      case 'PARITE': {
        this.clickDoviz = false;
        this.clickGramAltin = false;
        this.clickMaden = false;
        this.clickParite = true;
        this.clickZiynet = false;
        break;
      }
      case 'MADEN': {
        this.clickDoviz = false;
        this.clickGramAltin = false;
        this.clickMaden = true;
        this.clickParite = false;
        this.clickZiynet = false;
        break;
      }
      case 'GRAM ALTIN': {
        this.clickDoviz = false;
        this.clickGramAltin = true;
        this.clickMaden = false;
        this.clickParite = false;
        this.clickZiynet = false;
        break;
      }
    }
  }

  slide(direction) {
    var element = document.getElementById('tab-button');
    var scrollAmount = 0;
    var slideTimer = setInterval(() => {
      if (direction === 'left') {
        element.scrollLeft -= 1;
      } else {
        element.scrollLeft += 1;
      }
      scrollAmount += 1;
      if (scrollAmount >= 50) {
        window.clearInterval(slideTimer);
      }
    }, 25);
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }
}

<div class="row-contact col-12 p-4">
    <div class="title p-3">
        <h1>İLETİŞİM</h1>
    </div>
    <div class="subtitle p-3">
        <h5>
            Bizimle iş yapmakla ilgileniyorsanız veya başka herhangi bir sorunuz varsa, sizden haber almayı ve elimizden gelen her şekilde yardımcı olmayı dört gözle bekliyoruz.
        </h5>
    </div>
</div>
<div class="container">
    <form (ngSubmit)="onSubmit()" #contactForm="ngForm" autocomplete="off">
        <div class="form-group">
            <input type="text" name="name" id="name" [(ngModel)]="model.name" class="form-control" placeholder="İsim Soyisim" required #name="ngModel">
            <div *ngIf="name.invalid && (name.dirty || name.touched)" class="error">
                <div *ngIf="name.errors.required">
                    İsim Gereklidir
                </div>
            </div>
        </div>
        <div class="form-group">
            <input type="text" name="email" id="email" [(ngModel)]="model.email" class="form-control" placeholder="E-Mail" required email #email="ngModel">
            <div *ngIf="email.invalid && (email.dirty || email.touched)" class="error">
                <div *ngIf="email.errors.required">E-mail Gereklidir</div>
                <div *ngIf="email.errors.email">E-mail, geçerli bir e-mail adresi olmalıdır.</div>
            </div>
        </div>
        <div class="form-group">
            <input type="text" name="phone" id="phone" [(ngModel)]="model.phone" class="form-control" placeholder="Telefon">
        </div>
        <div class="form-group">
            <textarea style="height:139px;" name="message" id="message" [(ngModel)]="model.message" rows="5" class="form-control" placeholder="Mesaj" required #message="ngModel"></textarea>
            <div *ngIf="message.invalid && (message.dirty || message.touched)" class="error">
                <div *ngIf="message.errors.required">Mesaj Gereklidir</div>
            </div>
        </div>
        <div class="form-group" style="text-align: end;">
            <button [disabled]="!contactForm.form.valid" class="btn btn-success btn-lg">Gönder</button>
        </div>
    </form>
</div>


<section class="c-section c-contact-us-info-main-container">
    <div class="container-fluid c-f-w-v1">
        <div class="row">
            <div data-aos="fade-up" data-aos-delay="300" class="col-md-12 col-lg-4" style="visibility: visible;">
                <!--
                <img class="img-icon" src="assets/img/call.png">
        -->
                <div class="img-icon icon-phone"></div>
                <p class="c-p-v1">
                    <span class="c-f-w-bold">+90 (212) 520 36 53</span><br />
                    <span class="c-f-w-bold">+90 (212) 520 33 79</span>

                </p>
            </div>
            <div data-aos="fade-up" data-aos-delay="800" class="col-md-12 col-lg-4" style="visibility: visible;">
                <!--
                <img class="img-icon" src="../../assets/img/location.png">
        -->
                <div class="img-icon icon-location"></div>
                <p class="c-p-v1">Yenibosna Merkez mah. Ladin sok. Kuyumcukent no:4/2114 Bahçelievler / İstanbul</p>
            </div>
            <div data-aos="fade-up" data-aos-delay="1300" class="col-md-12 col-lg-4" style="visibility: visible;">
                <!--
                <img class="img-icon" src="../../assets/img/mail.png">
        -->
                <div class="img-icon icon-mail"></div>
                <p class="c-p-v1">info@ehgdoviz.com</p>
            </div>
        </div>
    </div>
</section>
<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3011.0101974851073!2d28.817783000000002!3d41.00315!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x29f0e075e9329f22!2sKuyumcukent%20AVM!5e0!3m2!1str!2str!4v1613118797407!5m2!1str!2str" width="600"
    height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
<div class="container">

    <p style="color:var(--navbar-text-color);" class="h3">KAMBİYO MEVZUATI İLE KIYMETLİ MADENLER  <br />SORUMLU TEDARİK ZİNCİRİ UYUM MEVZUATI İLE ŞİRKET POLİTİKAMIZ</p>
    <li style="color:var(--navbar-text-color); ">
        Kurumumuz Kıymetli Maden Zinciri Politika Dökümanı mevcuttur. Her hakkı Şirketimize ait olup 5846 sayılı Fikir ve Sanat Eserleri Kanunu kapsamında muhafaza edilir. 
    </li>

    <div style="color:var(--navbar-text-color); " class="h3 pt-3">A-Kambiyo Mevzuatı</div>
    <div style="color:var(--navbar-text-color); " class="h4 pt-3">A.1.Kanun ve Kararnamelerle Yapılan Düzenlemeler</div>
    <ul>
        <li style="color:var(--navbar-text-color); ">
            <a style="color:#9d8b54"
                href="https://ms.hmb.gov.tr/uploads/2018/11/T%C3%BCrk-Paras%C4%B1n%C4%B1n-K%C4%B1ymetini-Koruma-Hakk%C4%B1nda-1567-say%C4%B1l%C4%B1-Kanun.pdf"
                target="_blank">
                1567 sayılı Türk Parasının Kıymetini Koruma Kanunu (20 Şubat 1930)
            </a>
        </li>
        <li style="color:var(--navbar-text-color); ">
            <a style="color:#9d8b54"
                href="https://ms.hmb.gov.tr/uploads/2021/02/TurkParasiKiymetiniKorumaHakkinda32sayiliKarar-19022021.doc"
                target="_blank">
                Türk Parası Kıymetini Koruma Hakkında 32 Sayılı Karar (Kanun Gücünde Karar)
            </a>
        </li>
        <li style="color:var(--navbar-text-color); ">
            <a style="color:#9d8b54" href="https://ms.hmb.gov.tr/uploads/2018/11/elmaskarar.doc" target="_blank">
                Ham Elmas Dış Ticaretinin Düzenlenmesi ve Denetlenmesine Dair Karar (Kanun Gücünde Karar)
            </a>
        </li>
    </ul>
    <div style="color:var(--navbar-text-color);" class="h4 pt-3">
        <a style="color:var(--navbar-text-color);" href="https://www.hmb.gov.tr/finansal-piyasalar-ve-kambiyo-mevzuat"
            target="_blank">
            A.2.Hazine ve Maliye Bakanlığı (Finansal Piyasalar ve Kambiyo Genel Müdürlüğü) Tarafından Yapılan
            Düzenlemeler
        </a>
    </div>
    <ul>
        <li style="color:var(--navbar-text-color); ">
            <a style="color:#9d8b54"
                href="https://ms.hmb.gov.tr/uploads/2021/03/KIYMETLI-MADENLER-BORSASI-ARACI-KURULUSLARININ-FAALIYET-ESASLARI-ILE-KIYMETLI-MADENLER.pdf"
                target="_blank">
                Kıymetli Madenler Borsası Aracı Kuruluşlarının Faaliyet Esasları ile Kıymetli Madenler Aracı
                Kurumlarının Kuruluşu Hakkında Yönetmelik
            </a>
        </li>
        <li style="color:var(--navbar-text-color); ">
            <a style="color:#9d8b54"
                href="https://ms.hmb.gov.tr/uploads/2020/03/T%C3%BCrk-Paras%C4%B1-K%C4%B1ymetini-Koruma-Hakk%C4%B1nda-32-Say%C4%B1l%C4%B1-Karara-%C4%B0li%C5%9Fkin-Tebli%C4%9F-Tebli%C4%9F-No-2018-3245-Yeni-Tebli%C4%9F.pdf"
                target="_blank">
                Yetkili Müesseselerle İlgili Tebliğ (Yetkili Müesseselerle İlgili Tebliğ (2018-32/45)
            </a>
        </li>
        <li style="color:var(--navbar-text-color); ">
            <a style="color:#9d8b54"
                href="https://ms.hmb.gov.tr/uploads/2021/03/KIYMETLI-MADEN-STANDARTLARI-VE-RAFINERILERI-HAKKINDA-TEBLIG.pdf"
                target="_blank">
                Kıymetli Maden Standartları ve Rafineleri Hakkında Tebliğ (2006-1)
            </a>
        </li>
        <li style="color:var(--navbar-text-color); ">
            <a style="color:#9d8b54" href="https://www.resmigazete.gov.tr/eskiler/2018/04/20180418-7.htm"
                target="_blank">
                Kıymetli Maden ve Taş Analizinde Yetki Verilecek Ayar Evlerinin Standartları Seçimi ve Denetim
                Esaslarının Belirlenmesine Yönelik Tebliğ
            </a>
        </li>
        <li style="color:var(--navbar-text-color); ">
            <a style="color:#9d8b54"
                href="https://ms.hmb.gov.tr/uploads/2018/11/HamElmasDisTicaretininDuzenlenmesineveDenetlenmesineDairKararaIliskinTeblig.docx"
                target="_blank">
                Ham Elmas Dış Ticaretinin Düzenlenmesi ve Denetlenmesine Dair Karara İlişkin Tebliğ
            </a>
        </li>






        <li style="color:var(--navbar-text-color); ">
            <a style="color:#9d8b54" href="https://ms.hmb.gov.tr/uploads/2021/02/Ithalat-Genelgesi-25.02.2021-1.pdf"
                target="_blank">
                İthalata İlişkin Genelge (2020-1)
            </a>
        </li>
        <li style="color:var(--navbar-text-color); ">
            <a style="color:#9d8b54"
                href="https://ms.hmb.gov.tr/uploads/2021/03/2021.03.19-Mali-Guc-Tevsikine-Iliskin-Genelgesi-YM-2021-3.pdf"
                target="_blank">
                Mali Güç Tevsikine İlişkin Genelge (2021-3)
            </a>
        </li>
        <li style="color:var(--navbar-text-color); ">
            <a style="color:#9d8b54"
                href="https://ms.hmb.gov.tr/uploads/2020/03/Yetkili-M%C3%BCessese-Bilgi-Sistemine-%C4%B0li%C5%9Fkin-Genelge-2019-2.pdf"
                target="_blank">
                Yetkili Müessese Bilgi Sistemine İlişkin Genelge (2019-2)
            </a>
        </li>
        <li style="color:var(--navbar-text-color); ">
            <a style="color:#9d8b54"
                href="https://ms.hmb.gov.tr/uploads/2020/03/Yetkili-M%C3%BCessese-Bilgi-Sistemine-%C4%B0li%C5%9Fkin-Genelge-2018-1.pdf"
                target="_blank">
                Yetkili Müessese Bilgi Sistemine İlişkin Genelge (2018-1)
            </a>
        </li>
        <li style="color:var(--navbar-text-color); ">
            <a style="color:#9d8b54"
                href="https://ms.hmb.gov.tr/uploads/2018/11/2008-32-35-alt%C4%B1n-depo-tebli%C4%9F-07.09.2018.docx"
                target="_blank">
                Türk Parası Kıymetini Koruma Hakkında 32 Sayılı Karara İlişkin Tebliğ<br />
                Altın Depo Tebliği (Tebliğ No: 2008-32/35)
            </a>
        </li>
        <li style="color:var(--navbar-text-color); ">
            <a style="color:#9d8b54"
                href="https://ms.hmb.gov.tr/uploads/2020/01/2018-32-48-say%C4%B1l%C4%B1-Tebli%C4%9F.docx"
                target="_blank">
                Türk Parası Kıymetini Koruma Hakkında 32 Sayılı Karara İlişkin Tebliğ (İhracat Bedelleri Hakkında)
                (Tebliğ No: 2018-32/48)
            </a>
        </li>
        <li style="color:var(--navbar-text-color); ">
            <a style="color:#9d8b54"
                href="https://ms.hmb.gov.tr/uploads/2021/02/TPKKH-32-SAYILI-KARARA-ILISKIN-TEBLIG-2008-32-34.doc"
                target="_blank">
                Türk Parası Kıymetini Koruma Hakkında 32 Sayılı Karara İlişkin Tebliğ (Tebliğ No: 2008-32/34)
            </a>
        </li>

    </ul>




    <div style="color:var(--navbar-text-color);" class="h4 pt-3">
        A.3.Merkez Bankası Tarafından Yapılan Düzenlemeler
    </div>
    <ul>
        <li style="color:var(--navbar-text-color); ">
            <a style="color:#9d8b54"
                href="https://www.tcmb.gov.tr/wps/wcm/connect/d58bd2eb-b966-4765-a0cc-626c507449d8/Sermaye+Hareketleri+Genelgesi.pdf?MOD=AJPERES&CACHEID=ROOTWORKSPACE-d58bd2eb-b966-4765-a0cc-626c507449d8-nG6ylJB"
                target="_blank">
                Sermaye Hareketleri Genelgesi
            </a>
        </li>
        <li style="color:var(--navbar-text-color); ">
            <a style="color:#9d8b54"
                href="https://www.tcmb.gov.tr/wps/wcm/connect/4c35c9a6-b9de-47b3-8eaf-d62ce152dd57/1-Msayilibankamizgenelgesi.pdf?MOD=AJPERES&CACHEID=ROOTWORKSPACE-4c35c9a6-b9de-47b3-8eaf-d62ce152dd57-m5lIDgA"
                target="_blank">
                Türkiye Cumhuriyeti Merkez Bankası I-M Genelgesi
            </a>
        </li>
        <li style="color:var(--navbar-text-color); ">
            <a style="color:#9d8b54"
                href="https://www.tcmb.gov.tr/wps/wcm/connect/e90670f7-fc2e-453c-acd3-88098334b484/%C4%B0hracat+Genelgesi.pdf?MOD=AJPERES&CACHEID=ROOTWORKSPACE-e90670f7-fc2e-453c-acd3-88098334b484-nGqItz1"
                target="_blank">
                İhracat Genelgesi
            </a>
        </li>
    </ul>






    <div style="color:var(--navbar-text-color);" class="h4 pt-3">
        <a style="color:var(--navbar-text-color);" href="https://borsaistanbul.com/tr/sayfa/145/yonergeler"
            target="_blank">
            A.4.Borsa İstanbul A.Ş. Tarafından Yapılan Düzenlemeler
        </a>
    </div>
    <ul>
        <li style="color:var(--navbar-text-color); ">
            <a style="color:#9d8b54"
                href="https://borsaistanbul.com/files/kiymetli-madenler-araci-kuruluslari-ile-rafinerilerin-ic-kontrol-sistemi.pdf"
                target="_blank">
                Kıymetli Maden Aracı Kuruluşları ile Rafinerilerin İç Kontrol Sistemi ve Uyum Esasları Hakkında Yönerge
            </a>
        </li>
        <li style="color:var(--navbar-text-color); ">
            <a style="color:#9d8b54"
                href="https://borsaistanbul.com/files/sorumlu-tedarik-zinciri-uyum-yonergesi-31yon03-05032021.pdf"
                target="_blank">
                Sorumlu Tedarik Zinciri Uyum Yönergesi
            </a>
        </li>
        <li style="color:var(--navbar-text-color); ">
            <a style="color:#9d8b54"
                href="https://borsaistanbul.com/files/sorumlu-tedarik-zinciri-guvence-denetimi-yonergesi-31yon05-05032021.pdf"
                target="_blank">
                Borsa İstanbul Sorumlu Tedarik Zinciri Güvence Denetimi Yönergesi
            </a>
        </li>
        <li style="color:var(--navbar-text-color); ">
            <a style="color:#9d8b54"
                href="https://borsaistanbul.com/files/kiymetli-madenler-sorumlu-tedarik-zinciri-uyum-rehberi-05032021.pdf"
                target="_blank">
                Borsa İstanbul Kıymetli Madenler Sorumlu Tedarik Zinciri Uyum Rehberi
            </a>
        </li>
    </ul>


    <div style="color:var(--navbar-text-color); " class="h3 pt-3">B-SUÇ GELİRLERİNİN AKLANMASININ ÖNLENMESİ MEVZUATI <br />UYUM MEVZUATI İLE ŞİRKET POLİTİKAMIZ
    </div>
    <li style="color:var(--navbar-text-color); ">
        Kurumumuz Suç Gelirlerinin Aklanmasının ve Terörün Finansmanının Önlenmesi Hakkında Politika Dökümanı ( Uyum Programı ) mevcuttur. Her hakkı Şirketimize ait olup 5846 sayılı Fikir ve Sanat Eserleri Kanunu kapsamında muhafaza edilir. 
    </li>
    <div style="color:var(--navbar-text-color); " class="h4 pt-3">B.1.Kanunlarla Yapılan Düzenlemeler</div>
    <ul>
        <li style="color:var(--navbar-text-color); ">
            <a style="color:#9d8b54"
                href="https://www.mevzuat.gov.tr/mevzuat?MevzuatNo=5549&MevzuatTur=1&MevzuatTertip=5" target="_blank">
                5549 sayılı Suç Gelirlerinin Aklanmasının Önlenmesi Hakkında Kanun
            </a>
        </li>
        <li style="color:var(--navbar-text-color); ">
            <a style="color:#9d8b54"
                href="https://www.mevzuat.gov.tr/mevzuat?MevzuatNo=6415&MevzuatTur=1&MevzuatTertip=5" target="_blank">
                6415 sayılı Terörün Finansmanının Önlenmesi Hakkında Kanun
            </a>
        </li>
        <li style="color:var(--navbar-text-color); ">
            <a style="color:#9d8b54"
                href="https://www.mevzuat.gov.tr/mevzuat?MevzuatNo=7262&MevzuatTur=1&MevzuatTertip=5" target="_blank">
                7262 sayılı Kitle İmha Silahlarının Yayılmasının Finansmanının Önlenmesi Hakkında Kanun
            </a>
        </li>
    </ul>
    <div style="color:var(--navbar-text-color); " class="h4 pt-3">B.2. Yönetmeliklerle Yapılan Düzenlemeler</div>
    <ul>
        <li style="color:var(--navbar-text-color); ">
            <a style="color:#9d8b54"
                href="https://www.mevzuat.gov.tr/mevzuat?MevzuatNo=200713012&MevzuatTur=21&MevzuatTertip=5" target="_blank">
                Suç Gelirlerinin Aklanmasının ve Terörün Finansmanının Önlenmesine Dair Tedbirler Hakkında Yönetmelik
            </a>
        </li>
            <li style="color:var(--navbar-text-color); ">
                <a style="color:#9d8b54"
                    href="https://www.mevzuat.gov.tr/mevzuat?MevzuatNo=12426&MevzuatTur=7&MevzuatTertip=5" target="_blank">
                    Suç Gelirlerinin Aklanmasının ve Terörün Finansmanının 
                </a>
            </li>
                <li style="color:var(--navbar-text-color); ">
                    <a style="color:#9d8b54"
                        href="https://www.mevzuat.gov.tr/mevzuat?MevzuatNo=22694&MevzuatTur=7&MevzuatTertip=5" target="_blank">
                        Suç Gelirlerinin Aklanmasının ve Terörizmin Finansmanının
                    </a>
                </li>
                    <li style="color:var(--navbar-text-color); ">
                        <a style="color:#9d8b54"
                            href="https://www.mevzuat.gov.tr/mevzuat?MevzuatNo=18420&MevzuatTur=7&MevzuatTertip=5" target="_blank">
                            Terörizmin Finansmanının Önlenmesi Hakkında Kanunun 
                        </a>
                    </li>
                        <li style="color:var(--navbar-text-color); ">
                            <a style="color:#9d8b54"
                                href="https://www.mevzuat.gov.tr/mevzuat?MevzuatNo=200712454&MevzuatTur=3&MevzuatTertip=5" target="_blank">
                                Aklama Suçu İncelemesi Hakkında Yönetmelik
                            </a>
                        </li>
                    </ul>
                    <div style="color:var(--navbar-text-color); " class="h4 pt-3">B.3. Tebliğlerle Yapılan Düzenlemeler</div>
                    <ul>
                        <li style="color:var(--navbar-text-color); ">
                            <a style="color:#9d8b54"
                                href="https://www.mevzuat.gov.tr/mevzuat?MevzuatNo=11703&MevzuatTur=9&MevzuatTertip=5" target="_blank">
                                Terörün Finansmanına Yönelik Şüpheli İşlemlerin Bildirimi Genel Tebliği:
                            </a>
                        </li>
                        <li style="color:var(--navbar-text-color); ">
                            <a style="color:#9d8b54"
                                href="https://www.mevzuat.gov.tr/mevzuat?MevzuatNo=12073&MevzuatTur=9&MevzuatTertip=5" target="_blank">
                                Mali Suçları Araştırma Kurulu Genel Tebliği (Sıra No: 5)
                            </a>
                        </li>
                    <li style="color:var(--navbar-text-color); ">
                        <a style="color:#9d8b54"
                            href="https://ms.hmb.gov.tr/uploads/2019/01/MASAK-Genel-Tebli%C4%9Fi-S%C4%B1ra-No-7.docx" target="_blank">
                            Mali Suçları Araştırma Kurulu Genel Tebliği (Sıra No: 7)
                        </a>
                    </li>
                    <li style="color:var(--navbar-text-color); ">
                        <a style="color:#9d8b54"
                            href="https://www.mevzuat.gov.tr/mevzuat?MevzuatNo=13073&MevzuatTur=9&MevzuatTertip=5" target="_blank">
                            Mali Suçları Araştırma Kurulu Genel Tebliği (Sıra No: 8)
                        </a>
                    </li>
                    <li style="color:var(--navbar-text-color); ">
                        <a style="color:#9d8b54"
                            href="https://www.mevzuat.gov.tr/mevzuat?MevzuatNo=19810&MevzuatTur=9&MevzuatTertip=5" target="_blank">
                            Mali Suçları Araştırma Kurulu Genel Tebliği (Sıra No: 12)
                        </a>
                    </li>
                    <li style="color:var(--navbar-text-color); ">
                        <a style="color:#9d8b54"
                            href="https://www.mevzuat.gov.tr/mevzuat?MevzuatNo=19995&MevzuatTur=9&MevzuatTertip=5" target="_blank">
                            Mali Suçları Araştırma Kurulu Genel Tebliği (Sıra No: 13)
                        </a>
                    </li>
                    <li style="color:var(--navbar-text-color); ">
                        <a style="color:#9d8b54"
                            href="https://www.mevzuat.gov.tr/mevzuat?MevzuatNo=38566&MevzuatTur=9&MevzuatTertip=5" target="_blank">
                            Mali Suçları Araştırma Kurulu Genel Tebliği (Sıra No: 19)
                        </a>
                    </li>
                    </ul>
                    <div style="color:var(--navbar-text-color); " class="h3 pt-3">B.4. Rehberler 
                    </div>
                    <ul>
                        <li style="color:var(--navbar-text-color); ">
                            <a style="color:#9d8b54"
                                href="https://masak.hmb.gov.tr/rehberler" target="_blank">
                                Uyum Çalışmalarına Yönelik Sektörel Rehberler
                            </a>
                        </li>
                        <li style="color:var(--navbar-text-color); ">
                            <a style="color:#9d8b54"
                                href="https://masak.hmb.gov.tr/brosurler" target="_blank">
                                Uyum Çalışmalarına Yönelik Sektörel Broşürler
                            </a>
                        </li>
                        </ul>
                    
                    



</div>
import {Component, Input, OnInit} from '@angular/core';
import {CalculatorCurrency} from "../../_models/calculatorCurrency";
import {SocketData} from "../../_models/socketData";

@Component({
  selector: 'app-cevirici',
  templateUrl: './cevirici.component.html',
  styleUrls: ['./cevirici.component.scss']
})
export class CeviriciComponent implements OnInit {
  @Input() exchangeRates: SocketData[] = [];

  currentCurrency: string = 'USD';
  currentCurrencyTo: string = 'TRY';
  price: number = 1;

  pricecalc1: number = 1;
  pricecalc2: number = 1;

  selectedCurrency1 = 1;
  calculatorCurrencyList: CalculatorCurrency[] = [];
  isRead: boolean = false;
  returnPrice: number = 0;

  constructor() {
  }

  ngOnInit(): void {

  }

  getExchangeValue(): void {
    if (this.exchangeRates.length > 0) {
      const getFirstData: SocketData = this.exchangeRates[0];

      if (getFirstData.Code.length === 6) {
        this.currentCurrency = getFirstData.Code.substring(0, 3);
        this.currentCurrencyTo = getFirstData.Code.substring(3, 6);
      }

      let currencyId: number = 1;
      this.exchangeRates.forEach((element: SocketData) => {
        if (element.Code.length === 6) {
          const left: string = element.Code.substring(0, 3);
          const right: string = element.Code.substring(3, 6);
          if (this.calculatorCurrencyList.filter(w => w.currency === left).length === 0) {
            currencyId = currencyId + 1;
            this.calculatorCurrencyList.push({
              id: currencyId,
              currency: left,
              description: element.Description
            });
          }
          if (this.calculatorCurrencyList.filter(w => w.currency === right).length ===
            0) {
            currencyId = currencyId + 1;
            this.calculatorCurrencyList.push({
              id: currencyId,
              currency: right,
              description: element.Description
            });
          }
        }
      });

      if (this.calculatorCurrencyList.length > 0) {
        // this.curentCurrency = this.calculatorCurrencyList[0].currency;
        // this.curentCurrencyTo = this.calculatorCurrencyList[0].currency;
        this.pricecalc1 = this.price * this.exchangeRates[0].Bid;
      }

    }
    this.isRead = true;
  }

  calculateprice(askbid: boolean = false): number {
    let getPrice: number = 0;
    if (this.exchangeRates.length > 0) {
      if (this.isRead) {
        if (this.exchangeRates.filter((element) =>
          element.Code === (this.currentCurrency + this.currentCurrencyTo)).length > 0) {

          const getSocketData: SocketData = this.exchangeRates.filter(w => w.Code === (this.currentCurrency + this.currentCurrencyTo))[0];
          getPrice = askbid === false ? getSocketData.Ask : getSocketData.Bid;
          this.returnPrice = (getPrice * this.price);
        } else if (this.exchangeRates.filter((element) =>
          element.Code === (this.currentCurrencyTo + this.currentCurrency)).length > 0) {

          const getSocketData: SocketData = this.exchangeRates.filter(w => w.Code === (this.currentCurrencyTo + this.currentCurrency))[0];
          getPrice = askbid === false ? getSocketData.Ask : getSocketData.Bid;
          this.returnPrice = (this.price / getPrice);


        } else {
          const getSocketData1: SocketData = this.exchangeRates.filter(w => w.Code === (this.currentCurrency + 'TRY'))[0];
          const getPrice1 = askbid === false ? getSocketData1.Ask : getSocketData1.Bid;

          const getSocketData2: SocketData = this.exchangeRates.filter(w => w.Code === (this.currentCurrencyTo + 'TRY'))[0];
          const getPrice2 = askbid === false ? getSocketData2.Ask : getSocketData2.Bid;


          this.returnPrice = (getPrice1 / getPrice2);
        }
      } else {
        this.getExchangeValue();
      }

    }

    return this.returnPrice;

    // return this.pricecalc1 = (this.price * this.exchangeRates[0].Bid);
  }

  calculateprice1() {
    return this.pricecalc1 = (this.price * this.exchangeRates[0].Bid);
  }

  calculateprice2() {
    return this.pricecalc2 = (this.price * this.exchangeRates[0].Ask);
  }

  currencyChange(): void {
    // console.log(this.price)
  }

  currency1Change(): void {
  }

  currency2Change(): void {
  }

  reverseSymbols(): void {
    let left: string = this.currentCurrency;

    this.currentCurrency = this.currentCurrencyTo;
    this.currentCurrencyTo = left;

  }


}

<div class="row justify-content-center col-12">
  <div class="col-12 mb-20 text-center">
    <div class="row m-0 p-0">
      <div class="col-12 ">
        <div class="input-group mb-3">

          <input type="number" class="form-control" placeholder="Miktar" [(ngModel)]="this.price" (ngModelChange)="currencyChange()" aria-label="Username" aria-describedby="basic-addon1">
          <span class="input-group-text" id="basic-addon1"> {{this.currentCurrency}}</span>
        </div>
      </div>
    </div>
    <div class="row m-0 p-0">
      <div class="col-5 ">
        <ng-select class="drpCurrency1" [(ngModel)]="currentCurrency" [clearable]=false (change)='currency1Change()'>
          <ng-option *ngFor="let item of this.calculatorCurrencyList" [value]="item.currency">
            {{item.currency}}</ng-option>
        </ng-select>
      </div>
      <div class="col-2 align-self-center" (click)="reverseSymbols()">
        <div class="change">
        </div>
      </div>
      <div class="col-5 ">
        <ng-select class="drpCurrency2" [(ngModel)]="currentCurrencyTo" [clearable]=false (change)='currency2Change()'>
          <ng-option *ngFor="let item of this.calculatorCurrencyList" [value]="item.currency">
            {{item.currency}}</ng-option>
        </ng-select>
      </div>
    </div>
    <div class="row m-0 p-0">
      <div class="col-5 p-0  text-center">
        <div class="row m-0 p-0">
          <div class="col-12 p-0">
            Alış Fiyatı :
          </div>
          <div class="col-12 p-0">
            {{this.calculateprice(true)| number:'1.2-4'}} {{this.currentCurrencyTo}}
          </div>
        </div>
      </div>
      <div class="col-2 align-self-center">
        <div>
        </div>
      </div>
      <div class="col-5 p-0 text-center">
        <div class="row m-0 p-0">
          <div class="col-12 p-0">
            Satış Fiyatı :
          </div>
          <div class="col-12 p-0">
            {{this.calculateprice(false)| number:'1.2-4'}} {{this.currentCurrencyTo}}
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

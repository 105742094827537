<div class="request-form">
  <div class="container-fluid">
    <!--<div class="row">
          <div class="left-div col-sm-12 col-md-12 col-lg-12">
            <div class="banner-img"></div>
          </div>
        </div>-->

        <div class="toptext-container">
          <div class="infoaboutus">
              <div class="container">
                  <div class="text col-sm-12 col-md-12 col-lg-12">
                      <p class="paragraph">
                        Firmamız Borsa İstanbul A.Ş. üyesi olup A Grubu Yetkili Müessese olarak faaliyet göstermektedir.
                      </p>
                  </div>
              </div>
          </div>
      </div>

      
        
    <div class="container">
      <div class="right-div col-sm-12 col-md-12 col-lg-6">
        <div class="tab-button">
          <div class="col-4">MADEN</div>
          <div class="col-4 bid">ALIŞ</div>
          <div class="col-4 ask">SATIŞ</div>
        </div>
        <div class="overflow-box">
          <div class="grid-box" *ngFor="let data of dataList4 ; let i=index; trackBy : trackByPrice">
            <div class="grid-card"
                 [ngClass]="{'grid-card-up': data.askPercentChange >0,
                 'grid-card-down': data.askPercentChange <0}">
              <div class="grid-text col-4">
                {{data.Code}} <br>
                <div class="grid-date"> {{data.Time | date:'h:mm:ss'}}</div>
              </div>
              <div class="price col-4">
                {{data.Bid | number:'1.2-2'}}
              </div>
              <span class="rate-icon"
                    *ngIf="data.askPercentChange>0"
                    style="color: #3a9d37;padding: 0;text-align: center">
                <i style="font-size: 20px;" class="fas fa-caret-up"></i>
              </span>
              <span class="rate-icon"
                    *ngIf="data.askPercentChange<0"
                    style="color: #f25961;padding: 0;text-align: center">
                <i style="font-size: 20px;" class="fas fa-caret-down"></i>
              </span>
              <span class="rate-icon"
                    *ngIf="data.askPercentChange===0 || !data.askPercentChange"
                    style="color: orange;padding: 0;text-align: center">
                <i class="fa fa-minus"></i>
              </span>
              <div class="price col-4">
                {{data.Ask | number:'1.2-2'}} <br>
                <span *ngIf="data.askPercentChange>0" class="grid-all col-sm-3 col-md-3 col-lg-3"
                      style="color: #3a9d37">
            %{{data.askPercentChange}}
          </span>
                <span *ngIf="data.askPercentChange<0" class="grid-all col-sm-3 col-md-3 col-lg-3"
                      style="color: #f25961">
            %{{data.askPercentChange}}
          </span>
                <span *ngIf="data.askPercentChange==0 || !data.askPercentChange"
                      class="grid-all col-sm-3 col-md-3 col-lg-3"
                      style="color: orange">
            %{{data.askPercentChange}}.00
          </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right-div col-sm-12 col-md-12 col-lg-6">
        <div class="tab-button">
          <div class="col-4">DÖVİZ</div>
          <div class="col-4 bid">ALIŞ</div>
          <div class="col-4 ask">SATIŞ</div>
        </div>
        <div class="overflow-box">
          <div class="grid-box" *ngFor="let data of dataList3 ; let i=index; trackBy : trackByPrice">
            <div class="grid-card"
                 [ngClass]="{'grid-card-up': data.askPercentChange >0,
                 'grid-card-down': data.askPercentChange <0}">
              <div class="grid-text col-4">
                {{data.Code}} <br>
                <div class="grid-date"> {{data.Time | date:'h:mm:ss'}}</div>
              </div>
              <div class="price col-4">
                {{data.Bid | number:'1.4-4'}}
              </div>
              <span class="rate-icon"
                    *ngIf="data.askPercentChange>0"
                    style="color: #3a9d37;padding: 0;text-align: center">
                <i style="font-size: 20px;" class="fas fa-caret-up"></i>
              </span>
              <span class="rate-icon"
                    *ngIf="data.askPercentChange<0"
                    style="color: #f25961;padding: 0;text-align: center">
                <i style="font-size: 20px;" class="fas fa-caret-down"></i>
              </span>
              <span class="rate-icon"
                    *ngIf="data.askPercentChange===0 || !data.askPercentChange"
                    style="color: orange;padding: 0;text-align: center">
                <i class="fa fa-minus"></i>
              </span>
              <div class="price col-4">
                {{data.Ask | number:'1.4-4'}} <br>
                <span *ngIf="data.askPercentChange>0" class="grid-all col-sm-3 col-md-3 col-lg-3"
                      style="color: #3a9d37">
            %{{data.askPercentChange}}
          </span>
                <span *ngIf="data.askPercentChange<0" class="grid-all col-sm-3 col-md-3 col-lg-3"
                      style="color: #f25961">
            %{{data.askPercentChange}}
          </span>
                <span *ngIf="data.askPercentChange==0 || !data.askPercentChange"
                      class="grid-all col-sm-3 col-md-3 col-lg-3"
                      style="color: orange">
            %{{data.askPercentChange}}.00
          </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</div>

<!--<div class="phone-content">
  <div class="timeline">
    <div class="container1 left">
      <div data-aos="fade-up" class="content">
        <div class="applogo-button"></div>
      </div>
    </div>
    <div class="container1 telephone">
      <div data-aos="fade-up" class="content">
        <div class="mobile-image"></div>
      </div>
    </div>
    <div class="container1 left">
      <div data-aos="fade-up" class="content">
        <h2>Mobil Uygulama</h2>
        <p>Her zaman, her yerde fiyatlarımızı takip edebilirsiniz.</p>
      </div>
    </div>
    <div class="container1 right">
      <div data-aos="fade-up" class="content">
        <h2>Erişilebilirlik</h2>
        <p>Her zaman, her yerde fiyatlarımızı takip edebilirsiniz.</p>
      </div>
    </div>
    <div class="container1 left">
      <div data-aos="fade-up" class="content">
        <h2>Güvenli</h2>
        <p>Fiyatlarımıza en güvenli şekilde ulaşın.</p>
      </div>
    </div>
    <div class="container1 right">
      <div data-aos="fade-up" class="content">
        <h2>Kârlılık</h2>
        <p>Güncel fiyatlar ile kazancınızı en üst düzeye taşıyın.</p>
      </div>
    </div>
    <div class="container1 left">
      <div data-aos="fade-up" class="content">
        <h2>Zaman</h2>
        <p>Telefon görüşmeleri ile zaman kaybetmeyin ve fiyat kaçırmayın.</p>
      </div>
    </div>
    <div class="container1 right">
      <div data-aos="fade-up" class="content">
        <h2>Çevirici</h2>
        <p>Çevirici ile kurlar arası fiyat değişminizi görüntüleyebilirsiniz.</p>
      </div>
    </div>
  </div>
</div>-->
<!--
<section id=timeline>
  <h1>NEDEN BİZİMLE ÇALIŞMALISINIZ?</h1>
  <div class="demo-card-wrapper">
    <div class="demo-card demo-card&#45;&#45;step1" data-aos="fade-right">
      <div class="head">
        <div class="number-box">
          <span>01</span>
        </div>
        <h2><span class="small">text</span> TITLE</h2>
      </div>
      <div class="body">
        <p>text</p>
      </div>
    </div>
    <div class="demo-card demo-card&#45;&#45;step2" data-aos="fade-left">
      <div class="head">
        <div class="number-box">
          <span>02</span>
        </div>
        <h2><span class="small">text</span> TITLE</h2>
      </div>
      <div class="body">
        <p>text</p>
      </div>
    </div>
    <div class="demo-card demo-card&#45;&#45;step3" data-aos="fade-right">
      <div class="head">
        <div class="number-box">
          <span>03</span>
        </div>
        <h2><span class="small">text</span> TITLE</h2>
      </div>
      <div class="body">
        <p>text</p>
      </div>
    </div>

  </div>
</section>
-->
<div class="phone-content">
  <div class="row">
    <div class="subrow">
      <!-- feature item -->
      <div class="col-lg-6 col-md-12 align-self-center text-center">
        <div class="temp-wrapper col-md-12 col-6">
          <div class="px">
            <div class="px__body">
              <div class="px__body__cut"></div>
              <div class="px__body__speaker"></div>
              <div class="px__body__sensor"></div>

              <div class="px__body__mute"></div>
              <div class="px__body__up"></div>
              <div class="px__body__down"></div>
              <div class="px__body__right"></div>
            </div>

            <div class="px__screen">
              <div class="px__screen__">
                <div class="px__screen__frame">
                  <i class="fa fa-apple"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- feature item -->
      <div class="text-subcontent col-lg-6 col-md-12 align-self-center ">
        <div class="head">
          <div class="number-box">
            <i class="fa fa-mobile-alt"></i>
          </div>
          <h2>FİYATLARIMIZI MOBİL UYGULAMAMIZDAN TAKİP EDEBİLİRSİNİZ</h2>
        </div>
        <div class="body">
          <p>Fiyatlarımız artık her zaman ve her yerde yanınızda. <br>
            Mobil uygulamamız üzerinden güncel fiyatlarımızı <br>
            anlık grafikler ile takip edebilir ve karlılığınızı <br>
            doğru pozisyonu yakalayarak en üst düzeye <br>
            taşıyabilirsiniz.<br><br>
            Mobil Uygulamamızda Bulunan Bazı Özellikler:
          </p>
          <ul>
            <li><p>Portföy</p></li>
            <li><p>Çevirici</p></li>
            <li><p>Grafikler</p></li>
            <li><p>Favori</p></li>
          </ul>
          <div class="app-button">
            <div class="col-6" style="max-width: 200px;">
              <a type="button" href="https://apps.apple.com/tr/app/ehg-yetkili-m%C3%BCessese/id1546975080?l=tr"
                 target="_blank">
                <div>
                  APP STORE
                </div>
              </a>
            </div>
            <div class="col-6" style="max-width: 200px;">
              <a type="button" href="https://play.google.com/store/apps/details?id=com.aifasoft.eren_has&gl=TR"
                 target="_blank">
                <div>PLAY STORE</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


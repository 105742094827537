<div class="content-footer">
    <div class="who">
        <div style="height: 200px " class="wyre-container col-lg-4 col-md-2  d-none d-lg-block">
            <div class="footer-logo"></div>
        </div>
        <div class="wyre-container col-lg-4 col-md-5 col-sm-6">
            <div class="who-content">
                <h1>Adres</h1>
                Yenibosna Merkez mah. Ladin sok. Kuyumcukent no:4/2114 Bahçelievler / İstanbul
            </div>
        </div>
        <div class="wyre-container col-lg-4 col-md-5 col-sm-6">
            <div class="who-content">
                <h1>Telefon</h1>
                <a style="color:#000;" href="tel:02125203653">+90 (212) 520 36 53</a><br />
                <a style="color:#000;" href="tel:02125203379">+90 (212) 520 33 79</a>
            </div>
        </div>
        <div class="wyre-container col-lg-12 col-md-12 col-sm-12">
            Powered by <a style="margin-left: 5px" href="http://www.aifasoft.com/" target="_blank">AIFASOFT</a>
        </div>
    </div>
</div>
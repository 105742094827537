<div class="home-container">
  <div class="piece-div col-sm-12 col-md-4 col-lg-4">
    <div class="grid-container">
      <div class="tab-button">
        <div class="col-4">DÖVİZ</div>
        <div class="col-4 bid">ALIŞ</div>
        <div class="col-4 ask">SATIŞ</div>
      </div>
      <div class="overflow-box" id="currencyContainer">
        <div class="grid-box" *ngFor="let data of currencyList ; let i=index; trackBy : trackByPrice">
          <div class="grid-card"
               [ngClass]="{'grid-card-up': data.askPercentChange >0,
                 'grid-card-down': data.askPercentChange <0}">
            <div class="grid-text col-4">
              {{data.Code}} <br>
              <div class="grid-date"> {{data.Time | date:'h:mm:ss'}}</div>
            </div>
            <div class="price col-4">
              {{data.Bid | number:'1.4-4'}}
            </div>
            <span class="rate-icon"
                  *ngIf="data.askPercentChange>0"
                  style="color: #3a9d37;padding: 0;text-align: center">
                <i style="font-size: 20px;" class="fas fa-caret-up"></i>
              </span>
            <span class="rate-icon"
                  *ngIf="data.askPercentChange<0"
                  style="color: #f25961;padding: 0;text-align: center">
                <i style="font-size: 20px;" class="fas fa-caret-down"></i>
              </span>
            <span class="rate-icon"
                  *ngIf="data.askPercentChange===0 || !data.askPercentChange"
                  style="color: orange;padding: 0;text-align: center">
                <i class="fa fa-minus"></i>
              </span>
            <div class="price col-4">
              {{data.Ask | number:'1.4-4'}} <br>
              <span *ngIf="data.askPercentChange>0" class="grid-all col-sm-3 col-md-3 col-lg-3"
                    style="color: #3a9d37">
            %{{data.askPercentChange}}
          </span>
              <span *ngIf="data.askPercentChange<0" class="grid-all col-sm-3 col-md-3 col-lg-3"
                    style="color: #f25961">
            %{{data.askPercentChange}}
          </span>
              <span *ngIf="data.askPercentChange==0 || !data.askPercentChange"
                    class="grid-all col-sm-3 col-md-3 col-lg-3"
                    style="color: orange">
            %{{data.askPercentChange}}.00
          </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="piece-div col-sm-12 col-md-4 col-lg-4">
    <div class="grid-container">
      <div class="tab-button">
        <div class="col-4">MADEN</div>
        <div class="col-4 bid">ALIŞ</div>
        <div class="col-4 ask">SATIŞ</div>
      </div>
      <div class="overflow-box" id="goldContainer">
        <div class="grid-box" *ngFor="let data of goldList ; let i=index; trackBy : trackByPrice">
          <div class="grid-card"
               [ngClass]="{'grid-card-up': data.askPercentChange >0,
                 'grid-card-down': data.askPercentChange <0}">
            <div class="grid-text col-4">
              {{data.Code}} <br>
              <div class="grid-date"> {{data.Time | date:'h:mm:ss'}}</div>
            </div>
            <div class="price col-4">
              {{data.Bid | number:'1.2-2'}}
            </div>
            <span class="rate-icon"
                  *ngIf="data.askPercentChange>0"
                  style="color: #3a9d37;padding: 0;text-align: center">
                <i style="font-size: 20px;" class="fas fa-caret-up"></i>
              </span>
            <span class="rate-icon"
                  *ngIf="data.askPercentChange<0"
                  style="color: #f25961;padding: 0;text-align: center">
                <i style="font-size: 20px;" class="fas fa-caret-down"></i>
              </span>
            <span class="rate-icon"
                  *ngIf="data.askPercentChange===0 || !data.askPercentChange"
                  style="color: orange;padding: 0;text-align: center">
                <i class="fa fa-minus"></i>
              </span>
            <div class="price col-4">
              {{data.Ask | number:'1.2-2'}} <br>
              <span *ngIf="data.askPercentChange>0" class="grid-all col-sm-3 col-md-3 col-lg-3"
                    style="color: #3a9d37">
            %{{data.askPercentChange}}
          </span>
              <span *ngIf="data.askPercentChange<0" class="grid-all col-sm-3 col-md-3 col-lg-3"
                    style="color: #f25961">
            %{{data.askPercentChange}}
          </span>
              <span *ngIf="data.askPercentChange==0 || !data.askPercentChange"
                    class="grid-all col-sm-3 col-md-3 col-lg-3"
                    style="color: orange">
            %{{data.askPercentChange}}.00
          </span>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="piece-div col-sm-12 col-md-4 col-lg-4">
    <div class="grid-container">
      <div class="tab-button">
        <div class="col-4">SARRAFİYE</div>
        <div class="col-4 bid">ALIŞ</div>
        <div class="col-4 ask">SATIŞ</div>
      </div>
      <div class="overflow-box" id="parityContainer">
        <div class="grid-box" *ngFor="let data of parityList ; let i=index; trackBy : trackByPrice">
          <div class="grid-card"
               [ngClass]="{'grid-card-up': data.askPercentChange >0,
                 'grid-card-down': data.askPercentChange <0}">
            <div class="grid-text col-4">
              {{data.Code}} <br>
              <div class="grid-date"> {{data.Time | date:'h:mm:ss'}}</div>
            </div>
            <div class="price col-4">
              {{data.Bid | number:'1.2-2'}}
            </div>
            <span class="rate-icon"
                  *ngIf="data.askPercentChange>0"
                  style="color: #3a9d37;padding: 0;text-align: center">
                <i style="font-size: 20px;" class="fas fa-caret-up"></i>
              </span>
            <span class="rate-icon"
                  *ngIf="data.askPercentChange<0"
                  style="color: #f25961;padding: 0;text-align: center">
                <i style="font-size: 20px;" class="fas fa-caret-down"></i>
              </span>
            <span class="rate-icon"
                  *ngIf="data.askPercentChange===0 || !data.askPercentChange"
                  style="color: orange;padding: 0;text-align: center">
                <i class="fa fa-minus"></i>
              </span>
            <div class="price col-4">
              {{data.Ask | number:'1.2-2'}} <br>
              <span *ngIf="data.askPercentChange>0" class="grid-all col-sm-3 col-md-3 col-lg-3"
                    style="color: #3a9d37">
            %{{data.askPercentChange}}
          </span>
              <span *ngIf="data.askPercentChange<0" class="grid-all col-sm-3 col-md-3 col-lg-3"
                    style="color: #f25961">
            %{{data.askPercentChange}}
          </span>
              <span *ngIf="data.askPercentChange==0 || !data.askPercentChange"
                    class="grid-all col-sm-3 col-md-3 col-lg-3"
                    style="color: orange">
            %{{data.askPercentChange}}.00
          </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="piece-div col-sm-12 col-md-4 col-lg-4">
    <div class="title-rss">
      HABERLER
    </div>
    <div class="rss-container">
      <app-rss></app-rss>
    </div>
  </div>
  <div class="piece-div col-sm-12 col-md-4 col-lg-4">
    <div class="title-rss">
      ÇEVİRİCİ
    </div>
    <div class="rss-container">
      <app-cevirici [exchangeRates]="currencyList"></app-cevirici>
    </div>
  </div>
  <div class="piece-div col-sm-12 col-md-4 col-lg-4">
    <div class="chart">
      <app-chart [code]="code"></app-chart>
    </div>
  </div>

</div>

import {BrowserModule} from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HttpClientModule} from '@angular/common/http';
import {NavbarComponent} from './_components/navbar/navbar.component';
import localeTr from '@angular/common/locales/tr';
import {CommonModule, registerLocaleData} from '@angular/common';
import {FooterComponent} from './_components/footer/footer.component';
import {ChartComponent} from './_components/chart/chart.component';

registerLocaleData(localeTr, 'tr');
import {GoldComponent} from './gold/gold.component';
import {NotFoundComponent} from './_components/not-found/not-found.component';
import {HomeProvider} from './_services/home.provider';
import {RssComponent} from './_components/rss/rss.component';
import {ContactComponent} from './contact/contact.component';
import {HomeComponent} from './Home/home.component';
import {CurrencyComponent} from './currency/currency.component';
import {NewsService} from './_services/news.service';
import {FormsModule} from '@angular/forms';
import {AboutComponent} from './about/about.component';
import {MatDialogModule} from '@angular/material/dialog';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AngularMaterialModule} from './angular-metarial.module';
import {ChatboxComponent} from './_components/chatbox/chatbox.component';
import {WarningComponent} from './_components/warning-component/warning.component';
import {SidebarService} from './_services/siderbar.service';
import {CarouselComponent} from './_components/carousel/carousel.component';
import {NoRightClickDirective} from './no-right-click.directive';
import { CeviriciComponent } from './_components/cevirici/cevirici.component';
import {NgSelectModule} from "@ng-select/ng-select";
import { UyumComponent } from './uyum/uyum.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    NotFoundComponent,
    ContactComponent,
    RssComponent,
    ChartComponent,
    HomeComponent,
    GoldComponent,
    CurrencyComponent,
    AboutComponent,
    ChatboxComponent,
    WarningComponent,
    CarouselComponent,
    NoRightClickDirective,
    CeviriciComponent,
    UyumComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    MatDialogModule,
    AngularMaterialModule,
    BrowserAnimationsModule,
    CommonModule,
    BrowserModule,
    NgSelectModule
  ],
  providers: [
    HomeProvider,
    NewsService,
    SidebarService
  ],
  bootstrap: [AppComponent],
  entryComponents: [],
  exports: [
    RssComponent,
    ChartComponent,
    HomeComponent,
    GoldComponent,
    CurrencyComponent,
    FooterComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
}

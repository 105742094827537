import {Component, ElementRef, HostListener, OnInit, Renderer2} from '@angular/core';
import {Router} from '@angular/router';
import {ROUTING} from '../../shared/routing';
import {MatDialog} from '@angular/material/dialog';
import {SidebarService} from '../../_services/siderbar.service';
import {ThemeService} from '../../_services/theme.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  host: {
    '(document:click)': 'onClick($event)'
  }
})
export class NavbarComponent implements OnInit {
  time = new Date();
  navbarOpen = false;
  isCollapsed = true;
  lightOrDark: boolean = true;
  test = localStorage.getItem('isDarkMode');

  constructor(private router: Router,
              private renderer: Renderer2,
              public dialog: MatDialog,
              private themeService: ThemeService,
              private _eref: ElementRef) {
  }

  ngOnInit() {
    setInterval(() => {
      this.time = new Date();
    }, 1000);
  }

  route(page) {
    switch (page) {
      case 'home': {
        this.router.navigateByUrl(ROUTING.HOME);
        localStorage.setItem('selectedPage', page);

        break;
      }
      case 'currency': {
        this.router.navigateByUrl(ROUTING.CURRENCY);
        localStorage.setItem('selectedPage', page);

        break;
      }
      case 'gold': {
        this.router.navigateByUrl(ROUTING.GOLD);
        localStorage.setItem('selectedPage', page);

        break;
      }
      case 'info': {
        this.router.navigateByUrl(ROUTING.INFORMATION);
        localStorage.setItem('selectedPage', page);

        break;
      }
      case 'corp': {
        this.router.navigateByUrl(ROUTING.CORPORATE);
        localStorage.setItem('selectedPage', page);

        break;
      }
      case 'comm': {
        this.router.navigateByUrl(ROUTING.CONTACT);
        localStorage.setItem('selectedPage', page);

        break;
      }
      case 'uyum': {
        this.router.navigateByUrl(ROUTING.UYUM);
        localStorage.setItem('selectedPage', page);

        break;
      }
    }

  }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
    if (this.navbarOpen) {
      this.closeNavbar();
    } else {
      this.openNavbar();
    }
  }

  toggleTheme() {
    if (this.themeService.isDarkTheme()) {
      localStorage.setItem('isDarkMode', 'false');
      this.lightOrDark = true;
      this.themeService.setLightTheme();
    } else {
      localStorage.setItem('isDarkMode', 'true');
      this.lightOrDark = false;
      this.themeService.setDarkTheme();
    }
  }

  onClick(event) {
    if (!this._eref.nativeElement.contains(event.target)) // or some similar check
    {
      this.closeNavbar();
    }
  }

  closeNavbar() {
    this.navbarOpen = false;
  }

  openNavbar() {
    this.navbarOpen = true;
  }
}

<div class="about-container">
    <div class="infoaboutus">
        <div class="container">
            <div class="text col-sm-12 col-md-12 col-lg-12">
                <h1 class="title">HAKKIMIZDA</h1>
                <p class="paragraph">
                    2001 yılından beri sizlerle beraber devam ederek günümüze kadar gelen mesleki yolculuğumuzda ortak çabalarımızın neticesi olarak güven esaslı çalışmalar yaptık. Eren Has Group olarak şimdi 17. Yılımızda olmanın gururunu sizlerle beraber paylaşmak ise
                    bizim için en büyük değerlerin başına gelmektedir. Bundan sonraki süreçte de bu güzel duyguları daha derinden yaşamak ve yeni dostluklar kurmak için her daim iş hayatımızda sizlerin gümüş tedarikçisi olmaya, altın - döviz değişimi
                    konularında uygun fiyat politikmızla beraber yanınızda olacağız...
                </p>
            </div>
        </div>
    </div>
</div>
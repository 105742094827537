<div class="container">
  <p style="color:var(--navbar-text-color); ">Kvkk kapsamında; </p>

  <p style="color:white; "><em><a href="../../assets/pdf/ehg-docs/aydinlatmametni.pdf" target="_blank"><span
    style="color:#9d8b54">Kişisel Verilerin İşlenmesi Aydınlatma Metni</span></a></em>
  </p>
  <p style="color:white; "><em><a href="../../assets/pdf/ehg-docs/kameraaydinlatmametni.pdf" target="_blank"><span
    style="color:#9d8b54">Güvenlik Kameraları Hakkında Aydınlatma Metni</span></a></em>
  </p>
  <p style="color:white; "><em><a href="../../assets/pdf/ehg-docs/cercevesozlesme.pdf" target="_blank"><span
    style="color:#9d8b54">Çerçeve Sözleşme</span></a></em>
  </p>
  <p style="color:white; "><em><a href="../../assets/pdf/ehg-docs/masakuyumpolitikasi.pdf" target="_blank"><span
    style="color:#9d8b54">MASAK Uyum Politikası</span></a></em>
  </p>
  <p style="color:white; "><em><a href="../../assets/pdf/ehg-docs/musterikabulformu.pdf" target="_blank"><span
    style="color:#9d8b54">Müşteri Kabul Formu</span></a></em>
  </p>
  <p style="color:white; "><em><a href="../../assets/pdf/ehg-docs/musterikabulpolitikasi.pdf" target="_blank"><span
    style="color:#9d8b54">Müşteri Kabul Politikası</span></a></em>
  </p>
  <p style="color:white; "><em><a href="../../assets/pdf/ehg-docs/musteriyetkibelgesi.pdf" target="_blank"><span
    style="color:#9d8b54">Müşteri Yetki Belgesi</span></a></em>
  </p>
  <p style="color:white; "><em><a href="../../assets/pdf/ehg-docs/musteritaniformu.pdf" target="_blank"><span
    style="color:#9d8b54">Müşteri Tanı Formu</span></a></em>
  </p>
  <p style="color:white; "><em><a href="../../assets/pdf/ehg-docs/verisahibibasvuruformu.pdf" target="_blank"><span
    style="color:#9d8b54">Veri Sahibi Başvuru Formu</span></a></em>
  </p>

  <p style="color:white; "><em><a href="../../assets/pdf/ehg-docs/uyumraporu2.pdf" target="_blank"><span
    style="color:#9d8b54">Uyum Raporu</span></a></em>
  </p>

  <p style="color:white; "><em><a href="../../assets/pdf/ehg-docs/guvence.pdf" target="_blank"><span
    style="color:#9d8b54">Güvence Raporu</span></a></em>
  </p>
  <!--<p style="color:white; "><em><a href="../../assets/pdf/ehg-docs/sorumlu-tedarik-2024.pdf" target="_blank"><span
    style="color:#9d8b54">Sorumlu Tedarik Zinciri Uyum Raporu</span></a></em>
  </p>
  <p style="color:white; "><em><a href="../../assets/pdf/ehg-docs/ehg-guvence.pdf" target="_blank"><span
    style="color:#9d8b54">EHG Güvence Uyum Raporu</span></a></em>
  </p>-->
  <!-- <p style="color:white; "><em><a href="../../assets/pdf/guvence-raporu.pdf" target="_blank"><span
    style="color:#9d8b54">Güvence Raporu 2021</span></a></em>
  </p>
  <p style="color:white; "><em><a href="../../assets/pdf/uyum-rapor.pdf" target="_blank"><span
    style="color:#9d8b54">Uyum Raporu 2021</span></a></em>
  </p> -->

</div>

export interface Theme {
  name: string;
  properties: any;
}


export const light: Theme = {
  name: 'dark',
  properties: {
    '--logo-color': '#9b844e',
    '--logo-color1': '#273271',

    '--navbar-color': '#272727',
    '--navbar-hover-color': '#897343',
    '--navbar-bg': '#222222',
    '--red-navbar-color': '#891910',
    '--navbar-text-color': '#ffffff',

    '--background-color': '#ffffff',
    '--footer-color': '#891910',
    '--footer-text': 'rgb(172, 145, 85)',
    '--footer-text-down': '#f25961',
    '--footer-text-up': '#3a9d37',
    '--title-area': '#3b4963',
    '--title-area2': '#282828',
    '--title-color': '#121212',
    '--title-color2': '#131722',
    '--title-black-color': '#ffffff',
    '--title-black-color2': '#ffffff',
    '--scrollbar-color': '#3b4963',
    '--scrollbar-hover': '#3b4963',

    '--fas-icon': '#e7e7e7',
    '--fas-icon-hover': '#ffffff',
    '--prev-next-hover': 'rgba(255, 255, 255, 0.8)',

    '--btn-name-color': '#b98d26',
    '--btn-name-hover': '#801a11',
    '--btn-name-text': '#ffffff',

    '--grid-text': '#cd5c5c',
    '--grid-text2': '#cd5c5c',

    '--bg-white-color': '#292929',
    '--bg-white-color2': '#181818',
    '--bg-black-color': '#fefefe',
    '--background-tertiary-shadow': '0 20px 30px rgba(255, 255, 255, 0.1)',
    '--box-tertiary-shadow': '0 0 50px rgba(255, 255, 255, 0.5)',

    '--logo-image': 'url(assets/img/logo-white.png)',
    '--chart-theme': 'dark',
    '--banner-image': 'url(assets/img/banner1dark.jpg)',

    '--phone-image': 'url(assets/img/phone2.png)',
    '--applogo-image': 'url(assets/img/applogo1.png)',

    '--contact-image': 'url(assets/img/call.png)',
    '--contact-image1': 'url(assets/img/location.png)',
    '--contact-image2': 'url(assets/img/mail.png)',

    '--content-bg': '#ededed',
    '--input-bg': '#161a25',
    '--select-border': '#111111 #0a0a0a black',
    '--select-bg-img': 'linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.4))',
    '--select-box-shadow': 'inset 0 1px rgba(255, 255, 255, 0.1), 0 1px 1px rgba(0, 0, 0, 0.2)',
    '--select-dd-color': '#aaa',
    '--select-text-shadow': '0 1px black'
  }
};

export const dark: Theme = {
  name: 'light',
  properties: {
    '--logo-color': '#9b844e',
    '--logo-color1': '#273271',

    '--navbar-color': '#ffffff',
    '--navbar-hover-color': '#897343',
    '--navbar-bg': '#ffffff',
    '--red-navbar-color': '#000000',
    '--navbar-text-color': '#000000',

    '--background-color': 'rgba(18, 26, 33, 0.8)',
    '--footer-color': '#ad0000',
    '--footer-text': 'rgb(83,69,39)',
    '--footer-text-down': '#931d26',
    '--footer-text-up': '#286127',
    '--title-area': '#a08f6b',
    '--title-area2': '#d7c7a4',
    '--title-color': '#ffffff',
    '--title-color2': '#ffffff',
    '--title-black-color': '#000000',
    '--title-black-color2': '#ffffff',
    '--scrollbar-color': '#f1f1f1',
    '--scrollbar-hover': '#f1f1f1',

    '--fas-icon': '#e7e7e7',
    '--fas-icon-hover': '#ffffff',
    '--prev-next-hover': 'rgba(0, 0, 0, 0.8)',

    '--btn-name-color': '#b98d26',
    '--btn-name-hover': '#801a11',
    '--btn-name-text': '#ffffff',

    '--grid-text': '#ad0000',
    '--grid-text2': '#cd5c5c',

    '--bg-white-color': '#d4d4d4',
    '--bg-white-color2': '#f1f1f1',
    '--bg-black-color': '#0e0e0e',
    '--background-tertiary-shadow': '0 20px 30px rgb(0 0 0 / 10%)',
    '--box-tertiary-shadow': '0 0 50px rgb(0 0 0 / 50%)',

    '--logo-image': 'url(assets/img/logo-black.png)',
    '--chart-theme': 'light',
    '--banner-image': 'url(assets/img/banner1white.jpg)',


    '--phone-image': 'url(assets/img/phone1.png)',
    '--applogo-image': 'url(assets/img/applogo2.png)',
    '--contact-image': 'url(assets/img/call-black.png)',
    '--contact-image1': 'url(assets/img/location-black.png)',
    '--contact-image2': 'url(assets/img/mail-black.png)',
    '--content-bg': '#121212',
    '--input-bg': '#ffffff',
    '--select-border': 'white #f7f7f7 whitesmoke',
    '--select-bg-img': 'linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.06))',
    '--select-box-shadow': '0 1px 1px rgba(0, 0, 0, 0.08)',
    '--select-dd-color': '#62717a',
    '--select-text-shadow': '0 1px white'
  }
};

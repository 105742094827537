<div class="container">
  <table class="Table">
    <thead>
      <tr style="height:1.1pt">
        <td
          style="border:1pt solid var(--logo-color1); background:var(--logo-color1); width:140.05pt; padding:3.75pt 3.75pt 3.75pt 3.75pt; height:1.1pt">
          <p text-align="center" style="text-align:center;">
                    <span style="color:white">ŞİRKET BİLGİLERİ</span>
          </p>
        </td>
        <td
          style="border:solid var(--logo-color1) 1.0pt; background:var(--logo-color1); width:308.05pt; padding:3.75pt 3.75pt 3.75pt 3.75pt; height:1.1pt"
          width="411">
        </td>
      </tr>
    </thead>
      <tbody>
    <tr>
      <td>
        <p>
          <span>ŞİRKET TÜRÜ</span>
        </p>
      </td>
      <td>
        YETKİLİ MÜESSESE
      </td>
    </tr>
    <tr>
      <td>
        <p>
          <span>MERSİS</span>
        </p>
      </td>
      <td>
        76000696490018
      </td>
    </tr>
    <tr>
      <td>
        <p><span>TİCARET SİCİL NUMARASI</span></p>
      </td>
      <td>
        347885
      </td>
    </tr>
    <tr>
      <td>
        <p><span>TİCARET ÜNVANI</span>
        </p>
      </td>
      <td>EHG YETKİLİ MÜESSESE A.Ş.
      </td>
    </tr>
    <tr>
      <td>
        <p><span>TAAHÜT EDİLEN SERMAYE MİKTARI</span>
        </p>
      </td>
      <td>
        75.000.000 TL
      </td>
    </tr>
    <tr>
      <td>
        <p>
          <span>ÖDENEN SERMAYE MİKTARI</span>
        </p>
      </td>
      <td>
        75.000.000 TL
      </td>
    </tr>
    <tr>
      <td>
        <p><span>ŞİRKET TESCİL TARİHİ</span>
        </p>
      </td>
      <td>
        01.01.1996
      </td>
    </tr>
    <tr>
      <td>
        <p><span>KURULDUĞU ÜLKE</span>
        </p>
      </td>
      <td>
        TÜRKİYE
      </td>
    </tr>
    <tr>
      <td>
        <p><span>VERGİ DAİRESİ</span>
        </p>
      </td>
      <td>
        YENİBOSNA
      </td>
    </tr>
    <tr>
      <td>
        <p><span>VERGİ NUMARASI</span>
        </p>
      </td>
      <td>
        760 0069 649
      </td>
    </tr>
    <tr>
      <td>
        <p><span>SEKTÖR</span>
        </p>
      </td>
      <td>
        HER TÜRLÜ DÖVİZ ve KIYMETLİ MADEN ALIM VE SATIMI
      </td>
    </tr>
    <tr>
      <td>
        <p><span>YÖNETİM KURULU BAŞKANI</span>
        </p>
      </td>
      <td>
        MEHMET EREN
      </td>
    </tr>
    <tr>
      <td>
        <p><span>GENEL MÜDÜR</span>
        </p>
      </td>
      <td>
        NURETTİN ÖZMEN
      </td>
    </tr>
    <tr>
      <td>
        <p><span>UYUM GÖREVLİSİ</span>
        </p>
      </td>
      <td>
        ESER BİNGOL
      </td>
    </tr>
    <tr>
      <td>
        <p><span>UYUM GÖREVLİSİ YARDIMCISI</span>
        </p>
      </td>
      <td>
        İMREN ÖNDEŞ NAYİR
      </td>
    </tr>
    <tr>
      <td>
        <p><span>İÇ KONTROL VE TEDARİK ZİNCİRİ UYUM GÖREVLİSİ</span>
        </p>
      </td>
      <td>
        ESER BİNGÖL
      </td>
    </tr>
    <tr>
      <td>
        <p><span>DENETÇİ</span>
        </p>
      </td>
      <td>Edit Bağımsız Denetim Hizmetleri A.Ş.Kaptanpaşa Mah. Darülaceze Cad. Bilaş
        İş Merkezi B Blok No:33 Kat:5 Daire: 60 Okmeydanı - Şişli / İSTANBUL Tel : (0212) 220 57 03 Mail:
        mutabakat@editdenetim.com
      </td>
    </tr>


    <tr style="height:1.1pt">
      <td
        style="border:1pt solid var(--logo-color1); background:var(--logo-color1); width:140.05pt; padding:3.75pt 3.75pt 3.75pt 3.75pt; height:1.1pt">
        <p text-align="center" style="text-align:center;">
                  <span style="color:white">UYUM POLİTİKASI</span>
        </p>
      </td>
      <td
        style="border:solid var(--logo-color1) 1.0pt; background:var(--logo-color1); width:308.05pt; padding:3.75pt 3.75pt 3.75pt 3.75pt; height:1.1pt"
        width="411">
      </td>
    </tr>
    <tr>
      <td colspan="2">
        <p><a href="../../assets/pdf/EHG-Uyum-Politikası-MASAK- Web-2023.pdf" target="_blank">
          <span>MASAK Uyum Politika Dökümanı (PDF) </span>
        </a>
        </p>
        <p><a href="../../assets/pdf/bist.pdf" target="_blank">
          <span>BİST Uyum Politika Dökümanı (PDF) </span>
        </a>
        </p>
       
      </td> 
    </tr>





    <tr style="height:1.1pt">
      <td
        style="border:1pt solid var(--logo-color1); background:var(--logo-color1); width:140.05pt; padding:3.75pt 3.75pt 3.75pt 3.75pt; height:1.1pt">
        <p text-align="center" style="text-align:center;">
                  <span style="color:white">BAĞIMSIZ DENETİM RAPORLARI</span>
        </p>
      </td>
      <td
        style="border:solid var(--logo-color1) 1.0pt; background:var(--logo-color1); width:308.05pt; padding:3.75pt 3.75pt 3.75pt 3.75pt; height:1.1pt"
        width="411">
      </td>
    </tr>
    <tr>
      <td colspan="2">
        <p><a href="../../assets/pdf/ehg-docs/bagimsiz_denetim_2023.pdf">
          <span>2023 Bağımsız Denetim Raporu (PDF)</span>
        </a>
        </p>
      </td> 
    </tr>
    <tr>
      <td colspan="2">
        <p><a href="../../assets/pdf/EHG_2022.pdf">
          <span>2022 Bağımsız Denetim Raporu (PDF)</span>
        </a>
        </p>
      </td> 
    </tr>
    <tr>
      <td colspan="2">
        <p><a href="../../assets/pdf/BAGIMSIZ-DENETIM-RAPORU-2021.pdf">
          <span>2021 Bağımsız Denetim Raporu (PDF)</span>
        </a>
        </p>
      </td> 
    </tr>
    <tr>
      <td colspan="2">
        <p><a href="../../assets/pdf/BAGIMSIZ-DENETIM-RAPORU-2020.pdf">
          <span>2020 Bağımsız Denetim Raporu (PDF)</span>
        </a>
        </p>
      </td> 
    </tr>
    <tr>
      <td colspan="2">
        <p><a href="../../assets/pdf/BAGIMSIZ-DENETIM-RAPORU-2019.pdf">
          <span>2019 Bağımsız Denetim Raporu (PDF)</span>
        </a>
        </p>
      </td>
      
    </tr>
    <tr>
      <td colspan="2">
        <p><a href="../../assets/pdf/BAGIMSIZ-DENETIM-RAPORU-2018.pdf">
          <span>2018 Bağımsız Denetim Raporu (PDF)</span>
        </a>
        </p>
      </td> 
    </tr>
 <!--  -->

 <tr style="height:1.1pt">
  <td
    style="border:1pt solid var(--logo-color1); background:var(--logo-color1); width:140.05pt; padding:3.75pt 3.75pt 3.75pt 3.75pt; height:1.1pt">
    <p text-align="center" style="text-align:center;">
              <span style="color:white">GÜVENCE DENETİMİ RAPORLARI</span>
    </p>
  </td>
  <td
    style="border:solid var(--logo-color1) 1.0pt; background:var(--logo-color1); width:308.05pt; padding:3.75pt 3.75pt 3.75pt 3.75pt; height:1.1pt"
    width="411">
  </td>
</tr>
<tr>
  <td colspan="2">
    <p><a href="../../assets/pdf/ehg-docs/ehg-guvence.pdf" target="_blank">
      <span>2023 Güvence Raporu (PDF) </span>
    </a>
    </p>

    <p><a href="../../assets/pdf/guvence.pdf" target="_blank">
      <span>2022 Güvence Raporu (PDF) </span>
    </a>
    </p>

     <p><a href="../../assets/pdf/guvence-raporu.pdf" target="_blank">
      <span>2021 Güvence Raporu (PDF) </span>
    </a>
    </p>
  </td> 
 

</tr>


<tr style="height:1.1pt">
  <td
    style="border:1pt solid var(--logo-color1); background:var(--logo-color1); width:140.05pt; padding:3.75pt 3.75pt 3.75pt 3.75pt; height:1.1pt">
    <p text-align="center" style="text-align:center;">
              <span style="color:white">UYUM RAPORLARI</span>
    </p>
  </td>
  <td
    style="border:solid var(--logo-color1) 1.0pt; background:var(--logo-color1); width:308.05pt; padding:3.75pt 3.75pt 3.75pt 3.75pt; height:1.1pt"
    width="411">
  </td>
</tr>
<tr>
  <td colspan="2">
    <p><a href="../../assets/pdf/ehg-docs/sorumlu-tedarik-2024.pdf" target="_blank">
      <span>2023 Uyum Raporu (PDF) </span>
    </a>
    </p>
   
    <p><a href="../../assets/pdf/uyumraporu2.pdf" target="_blank">
      <span>2022 Uyum Raporu (PDF) </span>
    </a>
    </p>

     <p><a href="../../assets/pdf/uyum-rapor.pdf" target="_blank">
      <span>2021 Uyum Raporu (PDF) </span>
    </a>
    </p>
  </td> 
</tr>

 <!--  -->
    <tr style="height:1.1pt">
      <td
        style="border:1pt solid var(--logo-color1); background:var(--logo-color1); width:140.05pt; padding:3.75pt 3.75pt 3.75pt 3.75pt; height:1.1pt">
        <p text-align="center" style="text-align:center;">
                  <span style="color:white">İLETİŞİM BİLGİLERİ</span>
        </p>
      </td>
      <td
        style="border:solid var(--logo-color1) 1.0pt; background:var(--logo-color1); width:308.05pt; padding:3.75pt 3.75pt 3.75pt 3.75pt; height:1.1pt"
        width="411">
      </td>
    </tr>
    <tr>
      <td>
        <p><span>ADRES</span>
        </p>
      </td>
      <td>
        Yenibosna Merkez Mah.Ladin Sokak Kuyumcukent No:4/2114 Bahçelievler /İstanbul
      </td>
    </tr>
    <tr>
      <td>
        <p><span>TELEFON</span>
        </p>
      </td>
      <td>
        0212 520 36 53
      </td>
    </tr>
    <tr>
      <td>
        <p><span>FAX</span>
        </p>
      </td>
      <td>
        0212 520 33 79
      </td>
    </tr>
    <tr>
      <td>
        <p><span>E-POSTA</span>
        </p>
      </td>
      <td>
        info@ehgdoviz.com
      </td>
    </tr>
    </tbody>
  </table>

  <p style="margin-bottom:11px">&nbsp;</p>
</div>

import {Component, OnInit} from '@angular/core';
import '../../assets/js/smtp.js';
import AOS from 'aos';
import {FormControl, FormGroup} from "@angular/forms";
import {Contact} from "../_models/contactModel";

declare var Email;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  model: Contact = new Contact();
  submitted = false;
  f: FormGroup;

  constructor() {
    this.f = new FormGroup({
      name: new FormControl(),
      email: new FormControl(),
      phone: new FormControl(),
      message: new FormControl()
    });
  }

  ngOnInit() {
    AOS.init();
  }

  onSubmit() {
    this.submitted = true;

    /*
        Email.send({
          // SecureToken: "10ED90705DE8238A6DD8D007810DC1BA9E04",
          SecureToken: 'cd85fee3-d2aa-43cf-8755-69c91aff3dd8',
          To: 'info@aifasoft.com',
          From: 'gizemzorba@aifasoft.com',
          //From: 'cihan.turgut@aifasoft.com' ,
          Subject: 'Subject',
          Body: ` <i> ${this.model.email} Kulanıcısından bir mail </i> <br/> <b>İsim: </b>${this.model.name} <br />  <b>Mesaj:</b> <br /> ${this.model.message} <br><br>  `
        }).then(
          //message => alert(message)
          this.f.setValue({name: '', email: '', phone: '', message: ''})
        );
    */

    Email.send({
      Host: 'smtp.erenhasgumus.com.tr',
      Username: 'umit1eren@gmail.com',
      Password: 'password',
      To: 'info@erenhasgumus.com.tr',
      From: 'umit1eren@gmail.com',
      Subject: 'Subject',
      Body: ` <i> ${this.model.email} Kulanıcısından bir mail </i> <br/> <b>İsim: </b>${this.model.name} <br />  <b>Mesaj:</b> <br /> ${this.model.message} <br><br>  `
    }).then(
      this.f.setValue({name: '', email: '', phone: '', message: ''}));
  }

}
